// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

/** green-main **/
:root {
  --ion-color-green-main: #227447;
  --ion-color-green-main-rgb: 34, 116, 71;
  --ion-color-green-main-contrast: #ffffff;
  --ion-color-green-main-contrast-rgb: 255, 255, 255;
  --ion-color-green-main-shade: #1e663e;
  --ion-color-green-main-tint: #388259;
}

.ion-color-green-main {
  --ion-color-base: var(--ion-color-green-main);
  --ion-color-base-rgb: var(--ion-color-green-main-rgb);
  --ion-color-contrast: var(--ion-color-green-main-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-main-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-main-shade);
  --ion-color-tint: var(--ion-color-green-main-tint);
}

/** yellow-main **/

:root {
  --ion-color-yellow-main: #F9A119;
  --ion-color-yellow-main-rgb: 249, 161, 25;
  --ion-color-yellow-main-contrast: #000000;
  --ion-color-yellow-main-contrast-rgb: 0, 0, 0;
  --ion-color-yellow-main-shade: #db8e16;
  --ion-color-yellow-main-tint: #faaa30;
}

.ion-color-yellow-main {
  --ion-color-base: var(--ion-color-yellow-main);
  --ion-color-base-rgb: var(--ion-color-yellow-main-rgb);
  --ion-color-contrast: var(--ion-color-yellow-main-contrast);
  --ion-color-contrast-rgb: var(--ion-color-yellow-main-contrast-rgb);
  --ion-color-shade: var(--ion-color-yellow-main-shade);
  --ion-color-tint: var(--ion-color-yellow-main-tint);
}

/** green-light **/

:root {
  --ion-color-green-light: #A3CF5E;
  --ion-color-green-light-rgb: 163, 207, 94;
  --ion-color-green-light-contrast: #000000;
  --ion-color-green-light-contrast-rgb: 0, 0, 0;
  --ion-color-green-light-shade: #8fb653;
  --ion-color-green-light-tint: #acd46e;
}

.ion-color-green-light {
  --ion-color-base: var(--ion-color-green-light);
  --ion-color-base-rgb: var(--ion-color-green-light-rgb);
  --ion-color-contrast: var(--ion-color-green-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-green-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-green-light-shade);
  --ion-color-tint: var(--ion-color-green-light-tint);
}

/** white **/

:root {
	--ion-color-white: #ffffff;
	--ion-color-white-rgb: 255,255,255;
	--ion-color-white-contrast: #000000;
	--ion-color-white-contrast-rgb: 0,0,0;
	--ion-color-white-shade: #e0e0e0;
	--ion-color-white-tint: #ffffff;
}

.ion-color-white {
	--ion-color-base: var(--ion-color-white);
	--ion-color-base-rgb: var(--ion-color-white-rgb);
	--ion-color-contrast: var(--ion-color-white-contrast);
	--ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
	--ion-color-shade: var(--ion-color-white-shade);
	--ion-color-tint: var(--ion-color-white-tint);
}

.alert-radio-label.sc-ion-alert-ios {
  padding-left: 13px;
  padding-right: 13px;
  padding-top: 17px;
  padding-bottom: 17px;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-order: 0;
  order: 0;
  color: #000000;
  text-overflow: ellipsis;
  white-space: pre-line;;
  overflow: hidden;
}

.alert-tappable.sc-ion-alert-ios {
  height: 70px;
}

.sc-ion-searchbar-md-h {
  --background: var(--ion-background-color, #fff);
  --border-radius: 2px;
  --box-shadow: 0 2px 2px 0rgba(0, 0, 0, 0.14), 0 3px 1px -2pxrgba(0, 0, 0, 0.2), 0 1px 5px 0rgba(0, 0, 0, 0.12);
  --cancel-button-color: var(--ion-color-step-900, #1a1a1a);
  --clear-button-color: initial;
  --color: #000000;
  --icon-color: var(--ion-color-step-600, #666666);
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: inherit;
}

.sc-ion-searchbar-md-h {
  --placeholder-color: initial;
  --placeholder-font-style: initial;
  --placeholder-font-weight: initial;
  --placeholder-opacity: .5;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  color: var(--color);
  font-family: var(--ion-font-family, inherit);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}


@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66, 140, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #ffffff;
    --ion-background-color-rgb: 255, 255, 255;

    --ion-text-color: #000000;
    --ion-text-color-rgb: 0, 0, 0;

    --ion-color-step-50: #ffffff;
    --ion-color-step-100: #fafafa;
    --ion-color-step-150: #fafafa;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #d4d4d4;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #ffffff;

    --ion-card-background: #ffffff;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #ffffff;
    --ion-background-color-rgb: 255, 255, 255;

    --ion-text-color: #000000;
    --ion-text-color-rgb: 0, 0, 0;

    --ion-border-color: #222222;

    --ion-color-step-50: #ffffff;
    --ion-color-step-100: #fafafa;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #ffffff;

    --ion-toolbar-background: #ffffff;

    --ion-tab-bar-background: #ffffff;

    --ion-card-background: #fffff;
  }
}